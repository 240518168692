/* Global styles and imports */
@import "@ng-select/ng-select/themes/default.theme.css";
@import 'ngx-toastr/toastr';
@import 'bootstrap/scss/bootstrap';

/* Base styles */
html {
  scroll-behavior: smooth;
}

/* Links */
.a:hover {
  color: #0a58ca !important;
}

/* Select styles */
.ng-select {
  .ng-select-container {
    border: 0;
    border-radius: 0;
    background: transparent !important;

    .ng-value-container {
      padding-left: 0;
    }
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled {
  cursor: default;
  color: #000;
  font-size: 14px;
  margin-top: 10px;
  text-transform: uppercase;
}

/* Layout */
.position-relative {
  position: relative;
}

.page {
  max-width: 1128px;
  margin: 0 auto;

  &.grey {
    background-color: #fbfbfb;
  }
}

.page-wide {
  max-width: 80%;
  margin: 0 auto;
}

/* Typography */
h1 {
  color: #222;

  &.section-title {
    font-size: 64px !important;
    line-height: 80px;
    font-weight: 800;
    color: #000000;
  }
}

h3 {
  color: #707070;
}

h4 {
  font-size: 32px;
  line-height: 40px;
  color: #707070;
}

/* Spacing */
.mt-0 {
  margin-top: 0;
}

.mt-60 {
  margin-top: 60px;
}

/* Forms */
.input-block {
  background: #ffffff;
  box-shadow: 0px 3px 17px #2d2d2d17;
  opacity: 1;
  display: flex;
  flex-direction: column;
  padding: 9px 19px 2px;
  margin-right: 16px;

  label {
    margin-bottom: 7px;
    font-size: 14px;
    line-height: 18px;
    color: #000;
    font-weight: 500;
  }

  input {
    border: 0;
    color: rgb(133, 133, 133);

    &:focus {
      outline: none;
    }
  }
}

.theme-form {
  button {
    border: 0;
    background: #3876fa;
    box-shadow: 0px 3px 17px #2d2d2d17;
    color: #ffffff;
    padding: 20px 51px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 800;

    &:hover {
      background-color: #0853cc;
    }
  }
}

/* Date picker overrides */
.md-drppicker {
  color: #000 !important;
  font-size: 15px !important;

  th.month {
    color: #000;
    font-weight: 800;
  }

  .calendar th {
    font-weight: 400;
  }

  td,
  th {
    height: 2.5em !important;
    width: 2.5em !important;
  }

  td {

    &.available:hover,
    &.active span,
    &.active:hover span {
      background-color: #3876fa !important;
    }

    &.start-date span,
    &.end-date span {
      display: inline-block;
      height: 36px;
      line-height: 34px;
      width: 36px;
      border-radius: 50px;
      background-color: #3876fa !important;
    }

    &.in-range,
    &.active,
    &.active:hover {
      background-color: #EDF5FD !important;
    }

    &.active,
    &.active:hover {
      border-color: transparent;
      color: #fff;
    }
  }

  &.shown.drops-down-left {
    width: 560px;
  }
}

/* Contact form */
.contact-form {

  .ng-select.ng-select-single .ng-select-container .ng-value-container,
  .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
    border-bottom: 1px solid var(--clr-forms-border-color, #b3b3b3);
  }
}

.form-control {
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #b3b3b3;
  padding: 0.375rem 0.25rem;
  background: linear-gradient(to bottom, transparent 95%, hsl(198, 100%, 59%) 95%) no-repeat;
  background-size: 0 100%;
  transition: background-size 0.2s ease;

  &:focus {
    box-shadow: none;
    border-bottom-color: hsl(198, 100%, 59%);
    background-size: 100% 100%;
  }
}

.form-label {
  margin-bottom: 0.25rem;
  font-weight: 500;
  font-size: 14px;
}

/* Ukraine section */
.ukraine .title {
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
}

/* Navigation */
.nav-link {
  color: #000;

  &:hover,
  &:focus {
    color: inherit;
  }
}

.nav-tabs {
  .nav-link {
    border: 0;

    &.active,
    .nav-item.show & {
      border-bottom: 3px solid #073937;
    }
  }
}

.w-40 {
  width: 40%;
}

/* Responsive */
@media screen and (max-height: 875px) {
  .home-section.first {
    height: auto !important;
  }
}

@media screen and (max-width: 992px) {
  .page-wide {
    max-width: 100%;
    padding: 15px;
  }

  .menu-search {
    width: 205px !important;
  }

  .home-section.first {
    height: 500px !important;
  }
  header,
  .header {
    .main-menu ul li.destination-item .destination-dropdown {
      margin-top: 30px !important;
      .destinations-by-type-sidebar {
        padding: 20px !important;
        min-width: 200px !important;
      }

      .destinations-by-type-container {
        padding: 20px !important;
        width: 100%;
      }

      .state {
        width: 33% !important;
      }
    }
  }

}

@media screen and (max-width: 576px) {
  .w-sm-100 {
    width: 100% !important;
  }

  .page {
    padding: 0 20px;
  }



  .home-section {
    padding-top: 0px !important;

    &.first {
      .image-gallery-slides {
        margin-top: 40px;

        .image-gallery-slide {
          position: relative !important;
          min-width: inherit !important;
          top: auto !important;
          right: auto !important;
        }
      }
    }

    h1.section-title {
      font-size: 46px !important;
      line-height: 41px !important;
    }

    h4 {
      margin-top: 10px;
    }

    .booking-form {
      margin-top: 60px !important;
      flex-direction: column;

      .destination,
      .dates,
      .input-block {
        width: 100% !important;
        margin-bottom: 10px;
      }
    }

    .image-gallery-slides {
      display: none !important;
    }
  }

  .featured-hotels-slider .slide {
    margin-right: 0 !important;
  }

  .fairbridge-promise .title {
    margin-top: 40px;
    margin-bottom: 0 !important;
  }

  .fairbridge-map {
    padding: 80px 0px 0 0 !important;

    p {
      width: 100% !important;
    }
  }

  .contact-form {
    padding: 40px 10px 20px 10px !important;

    .title {
      font-size: 40px !important;
      line-height: 60px !important;
    }
  }
}